.xa-hidden-cells {
  position: absolute;
  pointer-events: none;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 100;
}

.xa-cell {
  z-index: 0;
  line-height: 1;
  position: relative;
  box-sizing: border-box;
}
