@keyframes ldio-a46a25ohh76 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.ldio-a46a25ohh76 div {
  position: absolute;
  width: 20px;
  height: 80px;
  animation: ldio-a46a25ohh76 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.ldio-a46a25ohh76 div:nth-child(1) {
  transform: translate(8px, 0);
  background: #e15b64;
  animation-delay: -0.6s;
}
.ldio-a46a25ohh76 div:nth-child(2) {
  transform: translate(48px, 0);
  background: #f47e60;
  animation-delay: -0.4s;
}
.ldio-a46a25ohh76 div:nth-child(3) {
  transform: translate(88px, 0);
  background: #f8b26a;
  animation-delay: -0.2s;
}
.ldio-a46a25ohh76 div:nth-child(4) {
  transform: translate(128px, 0);
  background: #abbd81;
  animation-delay: -1s;
}
.loadingio-spinner-bars-536qw5pfiys {
  width: 100%;
  height: 24px;
  overflow: hidden;
  transform: scale(1);
  transform-origin: top left;
  display: flex;
  flex-direction: row;
  z-index: 2000;
  margin-bottom: 16px;
}
.ldio-a46a25ohh76 {
  width: 60px;
  height: 80px;
  position: relative;
  transform: translateZ(0) scale(0.3);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-a46a25ohh76 div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

.updated {
  padding-left: 8px;
  height: 24px;
  margin-bottom: 16px;
}
