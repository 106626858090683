.close {
  width: 32;
  height: 32;
}

div.Snackbar {
  margin-bottom: 32px;
  border-radius: 0;
}

div.SnackbarContent {
  border-radius: 0;
}

div.MuiLinearProgress-colorPrimary {
  background-color: white;
}

div.MuiLinearProgress-barColorPrimary {
  background-color: grey;
}
